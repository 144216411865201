














































































import { Component, Vue } from 'vue-property-decorator'
import { apiAdminList } from '@/api/setting/permissions'
import {
    apikefuAdd,
    apikefuEdit,
    apikefuDetail
} from '@/api/application/service'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        MaterialSelect
    }
})
export default class ServiceEdit extends Vue {
    /** S Data **/
    loading = false
    identity: number | null = null

    // 添加客服表单数据
    form: any = {
        admin_id: '', // 管理员id
        nickname: '', // 客服昵称
        disable: 0, // 禁用状态，0-启用，1-禁用
        sort: '', // 排序
        avatar: '' // 头像路径
    }

    // 表单校验
    rules = {
        admin_id: [
            {
                required: true,
                message: '请选择管理员',
                trigger: ['blur', 'change']
            }
        ],
        nickname: [
            { required: true, message: '请输入客服昵称', trigger: 'blur' }
        ],
        avatar: [{ required: true, message: '请输入客服头像', trigger: 'blur' }]
    }

    // 用户选择框
    adminOption: any[] = []

    /** E Data **/

    /** S Computed **/
    get pageTitle() {
        if (this.identity) {
            return '编辑客服'
        }
        return '新增客服'
    }
    /** S Computed **/

    /** S Methods **/
    getAdminOption() {
        apiAdminList({
            page_type: 0
        }).then(data => {
            this.adminOption = data.lists
        })
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            // 请求发送
            const request = this.identity
                ? apikefuEdit({ id: this.identity, ...this.form })
                : apikefuAdd(this.form)
            request.then(data => {
                setTimeout(() => {
                    this.$router.back()
                }, 1000)
            })
        })
    }

    // 获取详情
    getDetails() {
        this.loading = true
        apikefuDetail({
            id: this.identity as number
        })
            .then(res => {
                Object.keys(res).map(key => {
                    this.$set(this.form, key, res[key])
                })
            })
            .catch(() => {
                // this.$message.error('数据初始化失败，请刷新重载！')
            })
            .finally(() => {
                this.loading = false
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        this.getAdminOption()
        if (!this.identity) {
            return
        }
        this.getDetails()
    }
    /** E Life Cycle **/
}
